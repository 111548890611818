<template>
  <UserWithAvatar
    v-if="props.column.label === $t('settingsPatientsTable.name')"
    :is-inactive="isInactive"
    :first-name="firstName"
    :last-name="lastName"
  />
  <span v-else-if="props.column.label === $t('settingsPatientsTable.owlId')">
    {{ props.row.id }}
  </span>
  <PatientUserStatusButton
    v-else-if="props.column.label === $t('settingsPatientsTable.status')"
    :account-data="props.row.account_information"
    :user-data="props.row"
  />
  <span
    v-else-if="props.column.label === $t('settingsPatientsTable.roles')"
    class="multi-line"
  >
    <div
      v-for="(role, index) in roles"
      :key="index"
    >
      <span v-if="!role.hasPatient">{{ role.label }}</span>
      <router-link
        v-else
        class="go-to-patient"
        :to="{ name: 'ClinicianPatient', params: {ccaId: $getEncodedId(role.ccaId), ccauId: $getEncodedId(role.ccauId)} }"
      >
        {{ role.label }}
      </router-link>
    </div>
  </span>
  <span
    v-else-if="props.column.label === $t('settingsPatientsTable.contactInfo')"
    class="multi-line"
  >
    <div
      v-for="(contact, index) in contactInfo"
      :key="index"
    >
      {{ contact }}
    </div>
  </span>
  <span
    v-else-if="props.column.label === 'MRN' && props.row.teams_information"
    class="multi-line"
  >
    <div
      v-for="(team, i) in props.row.teams_information"
      :key="i"
    >
      <span v-if="team.patient && team.patient.mrn">{{ team.patient.mrn }}</span>
    </div>
  </span>
</template>

<script>
import UserWithAvatar from '@/components/admin/settings/common/UserWithAvatar'
import PatientUserStatusButton from '@/components/admin/settings/extra/patientUsers/PatientUserStatusButton'
import CaregiversData from '@/data/caregivers-data'
import { STATUS_INACTIVE } from '@/data/userStatusValues'
import { Helpers } from '@/mixins/Helpers'
import { RespondentHelper } from '@/mixins/RespondentHelper'

export default {
  name: 'PatientUserRowItem',
  components: { UserWithAvatar, PatientUserStatusButton },
  mixins: [RespondentHelper, Helpers],
  props: ['props'],
  computed: {
    isInactive () {
      return this.props.row.account_information.status === STATUS_INACTIVE
    },
    lastName () {
      return this.props.row.person_information.last_name
    },
    firstName () {
      return this.props.row.person_information.first_name
    },
    roles () {
      return this.props.row.teams_information.reduce((carry, current) => {
        const ccauId = current.id
        const ccaId = current.assignment_id
        const label = this.buildRelationshipText(current)
        const hasPatient = current.patient && current.patient.person_information
        return [...carry, { ccauId, ccaId, label, hasPatient }]
      }, [])
    },
    contactInfo () {
      const response = []
      const phone = this.getPhoneFromArray(this.props.row.contact_information)
      const email = this.getEmailFromArray(this.props.row.contact_information)

      if (email && email.value) {
        response.push(email.value)
      }
      if (phone && phone.value) {
        response.push(this.$maskedPhoneHyphens(phone.value))
      }
      return response
    }
  },
  methods: {
    buildRelationshipText (respondent) {
      const relation = respondent.patient_relation
      const relationText = CaregiversData.find(cg => cg.key === relation) || {}

      // Is Patient.
      if (this.isPatientRelation(relation)) {
        return this.$t('patient')
      }

      // Is Respondent relation but not patient.
      if (!respondent.patient || !respondent.patient.person_information) {
        return `${relationText.value} - No patient`
      }

      // Is respondent relation and patient.
      const patientName = respondent.patient.person_information.full_name
      return `${relationText.value} of ${patientName}`
    }
  }
}
</script>
