<template>
  <div class="settings-patient-users">
    <vue-good-table
      :ref="tableReference"
      :total-rows="totalRecords"
      :columns="columns"
      :is-loading="isLoading"
      :rows="patientUsers"
      mode="remote"
      :row-style-class="rowStyleClassFn"
      style-class="vgt-table"
      :max-height="maxHeight"
      :fixed-header="true"
      :pagination-options="{
        enabled: true
      }"
      :search-options="{
        enabled: true,
        externalQuery: settingsSearch
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearchTermChange"
      @on-cell-click="customOpenEdit"
    >
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('noClinicalUsersFound') }}
      </div>
      <PatientUserRowItem
        slot="table-row"
        slot-scope="props"
        :props="props"
      />
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { SettingsCrud } from '@/mixins/SettingsCrud'
import { SettingsPatientUsers } from '@/mixins/TableColumnsHelper'
import { GET_PATIENT_USERS } from '@/store/modules/patientUsers/constants'
import { mapGetters, mapMutations } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import PaginationFooter from '@/components/common/PaginationFooter'
import PatientUserRowItem from '@/components/admin/settings/extra/patientUsers/PatientUserRowItem'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import { PATIENT_ROW_ACTIVE } from '@/store/modules/applicationState/constants'

export default {
  name: 'SettingsPatientUsers',
  components: { VueGoodTable, PaginationFooter, LoadingSpinner, PatientUserRowItem },
  mixins: [SettingsPatientUsers, SettingsCrud, RemoteTable],
  data () {
    return {
      tableReference: 'settingsPatientUsers',
      tableMaxHeight: 154,
      defaultSortOrder: 'asc',
      defaultSortField: 'patient_full_names',
      action: GET_PATIENT_USERS
    }
  },
  computed: {
    ...mapGetters({
      patientUsers: 'getPatientUsers',
      patientUserRowActive: 'getPatientUserRowActive'
    })
  },
  created () {
    this.loadItems()
    componentRefreshSingleton.addComponentToRefresh(this.$options.name, this)
  },
  destroyed () {
    componentRefreshSingleton.removeComponentToRefresh(this.$options.name)
    this.setPatientRowActive(null)
  },
  methods: {
    ...mapMutations({
      setPatientRowActive: PATIENT_ROW_ACTIVE
    }),
    customOpenEdit (params) {
      const notAllowed = [this.$t('settingsPatientsTable.status'), this.$t('settingsPatientsTable.roles')]
      if (!notAllowed.includes(params.column.label)) {
        this.openEdit(params)
      }
    },
    rowStyleClassFn (row) {
      const activeClass = row.id === this.patientUserRowActive ? 'row-active' : ''
      return `status_${row.account_information.status} ${activeClass}`
    },
    refreshView () {
      this.loadItems(true)
    }
  }
}
</script>
